.add-new-project {
  background: #ffffff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  margin-top: 50px;
  width: 487px;
}

.add-new-project:hover {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
}

.add-new-project__field {
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.add-new-project__label {
  padding: 0 20px;
  font-size: 15px;
  min-width: 150px;
  font-weight: 500;
  color: #748194;
}

.add-new-project__input {
  transition: all 0.3s ease;
  width: 60%;
  font-weight: bold;
  height: 35px;
  padding: 6px 15px;
  border-radius: 5px;
  outline: none;
  border: none;
  background: #d4dfff75;
  color: #748194;
  font-size: 14px;
}

.add-new-project__date:focus,
.add-new-project__date:hover {
  border-color: #007bff;
  box-shadow: 0px 0px 5px rgba(0, 123, 255, 0.5);
  outline: none;
  background: white;
}

.add-new-project__select:focus,
.add-new-project__select:hover {
  border-color: #007bff;
  box-shadow: 0px 0px 5px rgba(0, 123, 255, 0.5);
  outline: none;
  background: white;
}

.add-new-project__input:focus,
.add-new-project__input:hover {
  border-color: #007bff;
  box-shadow: 0px 0px 5px rgba(0, 123, 255, 0.5);
  outline: none;
  background: white;
}

.add-new-project__checkbox:hover {
  border-color: #007bff;
  box-shadow: 0px 0px 5px rgba(0, 123, 255, 0.5);
  outline: none;
  background: white;
}

.awesome-form .btn {
  border-radius: 5px;
  background: #007bff;
  color: #fff;
  transition: all 0.3s ease-in-out;
}

.add-new-project__date {
  font-weight: bold;
  height: 35px;
  padding: 6px 15px;
  border-radius: 5px;
  outline: none;
  border: none;
  background: #d4dfff75;
  color: #748194;
  font-size: 14px;
  transition: all 0.3s ease;
  min-width: 60%;
}

.add-new-project__select {
  font-weight: bold;
  height: 35px;
  padding: 6px 15px;
  border-radius: 5px;
  outline: none;
  border: none;
  background: #d4dfff75;
  color: #748194;
  font-size: 14px;
  transition: all 0.3s ease;
  min-width: 60%;
}

.add-new-project__select option {
  background-color: #fff;
  color: #333;
  font-size: 14px;
}


.add-new-project__create-button {
  text-align: end;
  padding: 25px 0;
}

.add-new-project__create-button.active button {
  animation: fadeIn 2s;
  opacity: 1;
  pointer-events: all;
}

.add-new-project__create-button button {
  pointer-events: none;
  animation: fadeOut 2s;
  opacity: 0;
  padding: 10px;
  border-radius: 7px;
  width: 100px;
  cursor: pointer;
  transition: all 0.3s ease;
  border-color: #007bff;
  box-shadow: 0px 0px 5px rgba(0, 123, 255, 0.5);
  outline: none;
  background: white;
  font-size: 14px;
  font-weight: 800;
  color: #6d575b;
}

/* .add-new-project__create-button button .add-new-project__create-button button:hover {
  outline: none;
  background: #5d5fef;
  box-shadow: 0px 0px 5px rgba(0, 123, 255, 0.5);
  color: white;
} */

.add-new-project__create-button button:hover {
  outline: none;
    background: #5d5fef;
    box-shadow: 0px 0px 5px rgba(0, 123, 255, 0.5);
    color: white;
}


.project-creation-status {

  display: flex;
  width: 320px;
  height: 200px;
  background-color: white;
  border-radius: 50px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.project-creation-status-overlay {
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  justify-content: center;
  align-items: center;
  background: linear-gradient(rgb(82 82 255 / 25%), rgb(255 255 255 / 25%));
}

.project-creation-status-overlay.active {
  display: flex;
}

.empty-field {
  border: 1px solid red;
}



#loader {
  display: none;
  position: absolute;
  top: 0;
  width: 100%;
}

.progress-bar {
  height: 5px;
  background: red;
  animation: loading 15s ease-in-out forwards;
}

#loader.loader-active {
  display: block;
}

@keyframes loading {
  to {
    width: 100%
  }
}

.error-log {
  color: red;
}

.project-log__container {
  width: 100%;
  max-width: 640px;
  position: absolute;
  bottom: 0;
  transform: translateY(calc(100% - 0px));
  background: #161e29;
  color: white;
  font-family: 'Poppins';
  border-radius: 5px;
  transition: transform .3s ease;
  border: 1px solid grey;
  z-index: 1001;

}

.project-log__main-container {
  max-height: 100vh;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  font-size: 10px;
}

.project-log__main-container.log-active .project-log__container {
  transform: translateY(0px);
}

.project-log-image__container {
  text-align: end;
  border-bottom: 1px solid grey;
  padding: 4px;
}

.project-log-image__container>img {
  width: 12px;
  cursor: pointer;
}

.project-log-image__container {
  text-align: end;
}
.project-log {
  padding: 10px;
}