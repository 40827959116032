.project-details__container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: Poppins, sans-serif;
  padding: 30px 50px;
}


.project-details__container .project-details__container-buttons {
  text-align: end;
  margin-bottom: -1px;
}

.project-details__container .project-details__container-buttons button {
  margin: 0 5px;
  padding: 0 10px;
  border-radius: 6px;
  font-size: 14px;
  height: 36px;
  line-height: 20px;
  text-align: center;
  text-transform: capitalize;
  font-family: Poppins, sans-serif;
  border-color: #007bff;
  outline: none;
  font-weight: 800;
  color: #6d575b;
  cursor: pointer;
  min-width: 120px;
}

.project-details__container .project-details__container-buttons button:hover {

  background-color: #5d5fef;
  border-radius: 6px;
  border: 2px solid rgb(229, 231, 235);
  color: rgb(255, 255, 255);
}

.project-details__container .project-details__cards {
  /* border: 1px solid #5d5fef; */
  background: white;
}

.project-details__container .project-details__container-data {
  display: flex;
  flex-direction: column;
  width: 100%;

}

.project-details__container .project-details__container-dropdown {
  display: flex;
}

.project-details__container .project-details__container-dropdown-list {
  min-width: 372px;
  font-weight: bold;
  height: 35px;
  padding: 6px 15px;
  border-radius: 5px;
  outline: none;
  border: none;
  background: #d4dfff75;
  color: #748194;
  font-size: 14px;
  transition: all 0.3s ease;
  font-family: Poppins, sans-serif;
}

.project-details__container .project-details__container-dropdown-list:hover {
  border-color: #007bff;
  box-shadow: 0px 0px 5px rgba(0, 123, 255, 0.5);
  outline: none;
  background: white;

}

.project-details__container .active>button {
  background-color: #5d5fef;
  border-radius: 6px;
  border: 2px solid rgb(229, 231, 235);
  color: rgb(255, 255, 255);
}

.project-details__container .project-details__cards {
  display: none;
}

.project-details__container .project-details__cards.project-active {
  display: block;
  width: 100%;
  min-height: 500px;
  border-radius: 5px 0 5px 5px;
}

.project-details__container .project-details__container-dropdown-section {
  display: flex;
  justify-content: space-between;
}

.project-details__container .project-details__container-heading {
  padding: 15px;
  font-size: 22px;
  color: #737791;
  font-weight: 600;
  font-family: 'Poppins';
}

.project-details__container .project-details__container-buttons.sync-data {
  display: none;
}

.project-details__container .project-details__container-buttons.sync-data.sync-button-active {
  display: block;
  text-align: start;
  padding: 20px 0 10px 10px;
}


.project-details__container .project-details__container-buttons.users-list button {
  background: inherit;
  border: none;
  position: relative;
  margin: 0;

}

.project-details__container .project-details__container-buttons.users-list button:hover {
  background: inherit;
  border: none;
  position: relative;
  color: black;
  
}

.project-details__container .project-details__container-buttons.users-list .active button {
  border-radius: 5px 5px 0px 0px;
  background: white;
  color: #6d575b;
  outline: none;

} 