@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

.side-bar {
  padding: 25px 0;
  font-family: "Poppins", sans-serif;
  min-width: 200px;
}

.side-bar__logo-image {
  display: flex;
  padding: 15px;
}

.side-bar__logo-image>p {
  margin: 0;
  display: flex;
  color: #151d48;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  font-weight: bold;
  font-size: 15px;
}

.side-bar__logo-image>img {
  padding: 5px;
  background: #5d5fef;
  border-radius: 8px;
  width: 27px;
}

/* .side-bar__header {
  display: flex;
  margin-top: 30px;
  padding: 8px;
  background: #5d5fef;
  min-width: 190px;
  color: white;
} */

/* .side-bar__header>h2 {
  margin: 0;
  font-size: 14px;
}

.side-bar__header-icon {
  padding: 0 13px;
  width: 17px;
} */
.side-bar .menu a:hover {
  text-decoration: none;
} 
.side-bar .menu a {
  text-decoration: none;
} 