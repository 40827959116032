.chrone-jobs-container {
    width: 100%;
    padding: 30px;
    height: 100%;

}
.date-picker-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    padding: 10px;
    font-family: poppins;
    font-size: 14px;
    color: rgb(44, 62, 80);
    margin:0px;


}
.date-picker-container input {
    margin: 0px;
}

.cButton {
    background: white;
    height: 40px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 10px;
    cursor: pointer;
    width: 78px;
    justify-content: center;
}

.cButton:hover {
    outline: none;
    background: #5d5fef;
    box-shadow: 0px 0px 5px rgba(0, 123, 255, 0.5);
    color: white;
}

.chrone-jobs-text-card {
    outline-color: #fff;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    background: white;
    width: 100%;
    color: rgb(44, 62, 80);
    padding: 20px;
    height: 500px;
    overflow-y: scroll;
}

.chrone-jobs-buttons .active-button {
    background-color: white;
    pointer-events: none;
    color: rgb(44, 62, 80);
}

.chrone-jobs-buttons button {
    background-color: #e8e8e8;
    color: #6d575b;
    font-weight: 500;
    font-family: poppins;
    border-width: 0;
    cursor: pointer;

}

.page-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
}