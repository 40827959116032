.App {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #e8e8e8;
    font-family: "Poppins", sans-serif;
    width: 100%;
    height: fit-content;
}

.App>div {
    width: 100%;
    height: 100vh;
}

.dashboard-header {
    width: 100%;
    background: #878787b8;
}

.dashboard-header h3 {
    margin: 0;
    padding: 10px;
}

.dashBoard-container {
    display: flex;
    height: fit-content;
    border-radius: 0 0 12px 12px;
    padding: 1px 1px;
    min-height: 100vh;
}

.dashBoard-container__side-bar {

    display: flex;
    justify-content: center;
    background-color: white;
    padding: 0 22px;
    border-right: 1px solid #e8e8e8;
}

.dashBoard-container__dash-board {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;

    background-size: cover;
    background: #e8e8e8;
}

.dashBoard-container__dash-board-component {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
}

.dashboard-container__header-container {
    width: 100%;
    text-align: end;
    padding: 15px 0px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.dashboard-container__header {
    display: flex;
    padding: 3px 10px;
    margin-right: 40px;
    background: #5d5fef;
    color: white;
    border-radius: 5px;
    font-size: 13px;
    font-family: "Poppins";
    justify-content: center;
    align-items: center;

    font-weight: 800;
}

.dashboard-container__header>img {
    padding-right: 7px;
}

.dashboard-container__header:hover {
    border-color: #007bff;
    box-shadow: 0px 0px 5px rgba(0, 123, 255, 0.5);
    outline: none;
    background: white;
    font-weight: 800;
    color: #6d575b;
    cursor: pointer;
}

.dashboard-container__header:hover>img {
    filter: invert(100%) sepia(100%) saturate(500%) hue-rotate(200deg);
}