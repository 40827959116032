.menu {
  padding: 20px 0;
}
.menu-item {
  width: 100%;
  padding: 8px 0;
  text-align: center;
  display: flex;
  align-items: center;
  margin: 13px 0px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.menu-item h3 {
  margin: 0;
  color: #737791;
  font-size: 13px;
  font-family: Poppins;

  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.menu-item:hover {
  background: #d9dcfa6b;
}
.menu-item:hover img {
  filter: invert(100%) sepia(100%) saturate(500%) hue-rotate(200deg);
}
.menu-item:hover h3 {
  filter: invert(100%) sepia(100%) saturate(500%) hue-rotate(200deg);
}

.menu-item.active {
  background: #d9dcfa6b;
}
.menu-item.active img {
  filter: invert(100%) sepia(100%) saturate(500%) hue-rotate(200deg);
}
.menu-item.active h3 {
  filter: invert(100%) sepia(100%) saturate(500%) hue-rotate(200deg);
}
.menu-item > img {
  padding: 1px 13px 0 20px;
  width: 25%;
}

.menu-item__active {
  padding: 12px 2px;
  position: absolute;
  transition: all 0.3s ease;
  margin-left: -18px;
  border-radius: 0px 8px 8px 0px;
}
.menu-item.active .menu-item__active {
  background: #5d5fef;
  transition: all 0.3s ease;
}
