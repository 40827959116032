.card-list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 50px 0;
}

.card-list .css-s18byi {
  margin: 20px;
  background-color: rgb(238, 242, 255);
  border-bottom-color: rgba(0, 0, 0, 0);
  box-shadow: none;
  color: rgb(44, 62, 80);
  border: 1px solid transparent;
  padding: 5px;
  border-radius: 0.5rem;
  padding: 2px;
  border-width: 1px;
  border-color: transparent;
}

.card-list .css-s18byi:hover {  
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(0, 0, 0, 0), 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}


.card__content-container {
  display: flex;
}

.card-list .css-zq6grw.card__name {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 2px;
}

.card-list .css-zq6grw.card__email {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
}

.card-list .css-s18byi .card__image-container {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding-right: 8px;
}

.card-list .css-s18byi .card__image-container img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}