@import url('https://fonts.googleapis.com/css2?family=Agdasima&family=Poppins&display=swap');



.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.card {
    width: 350px;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 15px;
    padding: 40px;
    backdrop-filter: blur(10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    margin-top: 100px;
}

h2 {
    text-align: center;
    margin-bottom: 20px;
}

form {
    display: flex;
    flex-direction: column;
}

label {
    margin-bottom: 5px;
}

input {
    padding: 10px;
    margin-bottom: 10px;
    border: none;
    border-radius: 5px;
}

button {
    padding: 10px;
    color: #498ffc;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.login-div {
    background-color: rgb(239, 239, 239);
        margin-top: 10px;
        padding: 10px;
        border-radius: 5px;
        text-align: center;
        cursor: pointer;
         color: #498ffc;
    }

.signg {
    margin-top: 10px;
}
.login-button{
    text-decoration: none;
    color: #498ffc;
}
@media (max-width: 480px) {
    .card {
        width: 100%;
        max-width: 350px;
    }
}